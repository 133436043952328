<template>
<div class="app-main__inner meetings">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-plugin icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Scheduled Zoom Meetings</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of scheduled meetings
                </div>
                <div class="card-body">
            <div class="overflow-auto">
      <b-row>

      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="topic">Topic</b-form-checkbox>
            <b-form-checkbox value="agenda">Agenda</b-form-checkbox>
            <b-form-checkbox value="timezone">Time Zone</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table striped hover :items="allMeetings" :per-page="perPage"
                :current-page="currentPage" :fields="meetingfields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                 <template  #cell(id)="data">
                   <b-button v-if="user.is_admin=='T'" variant="outline-info" class="minWidth" @click="updateMeeting(data.item.id)">
                     <i class="fa fa-edit" ></i>
                   </b-button>
                   <b-button v-if="user.is_admin=='T'" style="padding: 7px 13px;" class="ml-2" variant="outline-warning" @click="deleteMeeting(data.item.id)">
                     <i class="fa fa-calendar-times-o" ></i>
                   </b-button>
                   <b-button v-if="user.is_admin=='T'" style="padding: 7px 13px;" class="ml-2" variant="outline-success" @click="startMeeting(data.item.id)">
                     <i class="fa fa-play-circle" ></i>
                   </b-button>
                  </template>
                  <template #cell(join_url)="data">
                   <b-button target="_blank"  class="joinurl" variant="link" :href="data.item.join_url">
                     <i class="pe-7s-link" ></i>
                   </b-button>
                  </template>
                  <!-- <template #cell(start_url)="data">
                   <b-button target="_blank"  class="joinurl" variant="link" :href="data.item.start_url">
                     <i class="pe-7s-link" ></i>
                   </b-button>
                  </template> -->
                 <template #cell(topic)="data">
                            <i>{{ data.item.topic }}</i>
                          </template>
                          <template #cell(agenda)="data">
                            <i>{{ data.item.agenda }}</i>
                          </template>
                          <template  #cell(start_time)="data">
                            <i>{{changeDate(data.item.start_time, "fullDate")}}</i>
                          </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
                </div>
              </div>
            </div>
        </div>
    </div>
     <b-modal size="lg" id="updateMeeting" ref="updateMeeting" hide-header hide-footer>
    <app-CreateMeeting :closeModal="closePopUp" :selectedMeeting="selectedMeeting"/>
  </b-modal>
</div>
</template>
<script>
import CreateMeeting from './createMeeting'
import moment from "moment";
import { mapActions,mapGetters } from 'vuex'
export default {
    props:['users'],
    components:{
      'app-CreateMeeting':CreateMeeting
    },
    data(){
        return{
        perPage: 10,
        sortDirection: 'asc',
        currentPage: 1,
        popupTitle:'',
        minDate:'',
        maxDate:'',
        totalRows: 1,
        filter: null,
        filterOn: [],
        meetingfields: [
          {
            key: 'id',
            label: '',
            sortable: false,
            class:'minWidth'
          },
          {
            key: 'id',
            label: '',
            sortable: false,
          }
          ,{
            key: 'topic',
            label: 'Topic',
            sortable: true
          },
          {
            key: 'agenda',
            label: 'Meeting Agenda',
            sortable: true
          },
          {
            key: 'start_time',
            label: 'Start Time',
            sortable: true,
          },
          // {
          //   key: 'start_url',
          //   label: 'Start URL',
          //   sortable: true,
          // },
           {
            key: 'join_url',
            label: 'Url',
            sortable: false,
          },
        ],
        selectedMeeting:null,
        allMeetings:[],
        user:null
        }
    },
    computed: {
      rows() {
        return this.allMeetings.length
      }
    },
    methods:{
       ...mapActions({
            postZoomQuery:'zoommeeting/postZoomQuery',
        }),
        ...mapGetters({
            getMeetings:'zoommeeting/getMeetings',
        }),
      resetModal(){
        this.user={
          user_id:0,
          user_type:'',
          user_package:'',
          pteExpiry:null,
          coachingExpiry:null,
          ptePackage:null,
          coaching:null
        }
      },
      closePopUp()
      {
        this.$refs['updateMeeting'].hide()
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      minMaxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      //minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(minDate.getDate()+1)
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth() + 12)
      //maxDate.setDate(15)
        this.minDate=minDate
        this.maxDate=maxDate
    },
    changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("ddd, MMMM Do YYYY HH:mm");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        updateMeeting(id){
            //console.log(id)
            var data={
              url:'zoommeeting/getMeetingById',
              meetingId:id
          }
        this.postZoomQuery(data).then(()=>{
            const response=this.getMeetings()
            if(response==null){
              this.$alert('Issue on meeting reterival contact IT Team','Issue','error')
            }   
            else if(response.success==1){
                    this.selectedMeeting=response.result
                    this.$refs['updateMeeting'].show()
                    //console.log(response.result)
                }
                else this.$alert('Issue on meeting reterival contact IT Team','Issue','error')

            });
        },
        hideMeetingPopup(){
          this.$refs['updateMeeting'].hide()
        },
        startMeeting(id){
          var data={
                meetingId:id,
                 url:'zoommeeting/getMeetingById',
            }
            return this.$confirm("Are you sure you want to start this meeting?",
                            "Meeting Start",
                            "question",
                            {
                                confirmButtonText: "Confirm"
                            }).then(()=>{
                              this.postZoomQuery(data).then(()=>{
                              const response=this.getMeetings()
                              if(response==null){
              this.$alert('Issue on meeting reterival contact IT Team','Issue','error')
            }   
            else if(response.success==1){
                                      this.selectedMeeting=response.result
                                      window.open(this.selectedMeeting.start_url, "_blank");  
                                      //console.log(response.result)
                                  }
                                  else this.$alert('Issue on meeting retrieval contact IT Team','Issue','error')

                              });
                            })
        },
        deleteMeeting(id){
            var data={
                meetingId:id,
                url:'zoommeeting/deleteMeetingById'
            }
            return this.$confirm("Are you sure you want to delete this meeting?",
                            "Confirm Delete",
                            "question",
                            {
                                confirmButtonText: "Confirm"
                            }).then(()=>{
                              this.postZoomQuery(data).then(()=>{
                                const response=this.getMeetings()
                                if(response==null){
              this.$alert('Issue on meeting reterival contact IT Team','Issue','error')
            }   
            else if(response.success==1){
                                    this.fetchMeetings()
                                    return this.$alert('Meeting deleted successfuly','Deleted','success')
                                }
                                else this.$alert('Issue on deleting meeting please contact IT Team','Faild','error')
                            })
            
          ////console.log(this.allMeetings)
      });
        },
      fetchMeetings(){
        this.user=this.$ls.get('user')
          var data={
              url:'zoommeeting/getZoomMeetingList',
              userId:process.env.VUE_APP_ZOOM_USER_ID
          }
       this.postZoomQuery(data).then(()=>{
          const response=this.getMeetings()
          //console.log(response)
          if(response==null){
              this.$alert('Issue on meeting reterival contact IT Team','Issue','error')
            }   
            else if(response.success==1){
              this.allMeetings=response.result.meetings.slice().reverse()
          }
          ////console.log(this.allMeetings)
      });
      }
    },
    beforeMount(){
        this.fetchMeetings()
    }
}
</script>
<style>
a.joinurl i{
        font-size: 19px;
    font-weight: 900;
    color: #7b7dc3;
}
td.minWidth{
  min-width: 200px;
}
</style>